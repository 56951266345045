import { Workbox, messageSW } from 'workbox-window';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then(registration => {
      console.log(registration);
    })
    .catch(error => console.error(error));
}

// let deferredPromt;
// const button = document.querySelector("#a2hs");
// window.addEventListener("beforeinstallprompt", event => {
//   // Prevent the mini-infobar from appearing on mobile
//   event.preventDefault();
//    // Stash the event so it can be triggered later.
//   deferredPromt = event;
//   // Update UI notify the user they can install the PWA
//   showInstallPromotion();
//
// });
//
// function showInstallPromotion(){
// button.style.display = "block";
// };
//
// function hideInstallPromotion(){
// button.style.display = "none";
// };
//
// button.addEventListener("click", () => {
//   // Hide the app provided install promotion
//   hideInstallPromotion();
//   // Show the install prompt
//   deferredPromt.prompt();
//    // Wait for the user to respond to the prompt
//   deferredPromt.userChoice.then(result => {
//     console.log(result.outcome);
//     //TODO: send to analytics
//     deferredPromt = null;
//   })
// });


if ('serviceWorker' in navigator) {
  const wb = new Workbox('sw.js');

  wb.addEventListener('installed', event => {
    if (event.isUpdate) {
      if (confirm(`New content is available!. Click OK to refresh`)) {
        window.location.reload();
      }
    }
  });

  wb.register();
}

// if ('serviceWorker' in navigator) {
//   const wb = new Workbox('/sw.js');
//   let registration;
//
//   const showSkipWaitingPrompt = (event) => {
//     console.log("showSkipWaitingPrompt");
//     console.log(event);
//     // `event.wasWaitingBeforeRegister` will be false if this is
//     // the first time the updated service worker is waiting.
//     // When `event.wasWaitingBeforeRegister` is true, a previously
//     // updated service worker is still waiting.
//     // You may want to customize the UI prompt accordingly.
//
//     // Assumes your app has some sort of prompt UI element
//     // that a user can either accept or reject.
//     const prompt = createUIPrompt({
//
//       onAccept: async () => {
//         console.log("onAccept");
//         // Assuming the user accepted the update, set up a listener
//         // that will reload the page as soon as the previously waiting
//         // service worker has taken control.
//         wb.addEventListener('controlling', (event) => {
//           console.log("controlling");
//           window.location.reload();
//         });
//
//         if (registration && registration.waiting) {
//           console.log("waiting");
//           // Send a message to the waiting service worker,
//           // instructing it to activate.
//           // Note: for this to work, you have to add a message
//           // listener in your service worker. See below.
//           messageSW(registration.waiting, {type: 'SKIP_WAITING'});
//         }
//       },
//
//       onReject: () => {
//         prompt.dismiss();
//       }
//     });
//   };
//
//   // Add an event listener to detect when the registered
//   // service worker has installed but is waiting to activate.
//   wb.addEventListener('waiting', showSkipWaitingPrompt);
//   wb.addEventListener('externalwaiting', showSkipWaitingPrompt);
//
//   wb.register().then((r) => registration = r);
// }
